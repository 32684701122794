export const useLogout = () => {
  const { tokenManager } = useHotelTokenManager();
  const alert = useAlert();
  const router = useRouter();
  const { load, loading } = useLoading(
    async () => await tokenManager.invalidateCredential()
  );
  return {
    loading,
    logout: async () => {
      try {
        if (loading.value) {
          alert.error('ログアウト処理中です。');
          return;
        }
        await tokenManager;
        await load();
        await router.push('/login');
      } catch (e) {
        captureException(e);
        alert.error(
          '通信に失敗しました。お手数ですが、時間をおいて再度お試しください。'
        );
      }
    },
  };
};
